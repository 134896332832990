@font-face {
  font-family: 'Etelka Text Pro';
  src: url('Etelka-Text-Pro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Etelka Text Pro';
  src: url('Etelka-text-pro-bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Etelka Text Pro';
  src: url('Etelka-Text-Pro-Italic.ttf') format('truetype');
  font-style: italic;
  font-display: swap;
}

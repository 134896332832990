@font-face {
  font-family: 'Myriad Pro';
  src: url('MYRIADPRO-BOLD.OTF') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro Cond';
  src: url('MYRIADPRO-BOLDCOND.OTF') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('MYRIADPRO-BOLDCONDIT.OTF') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('MYRIADPRO-BOLDIT.OTF') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro Cond';
  src: url('MYRIADPRO-COND.OTF') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro COND';
  src: url('MYRIADPRO-CONDIT.OTF') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro Light';
  src: url('MyriadPro-Light.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('MYRIADPRO-REGULAR.OTF') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('MYRIADPRO-SEMIBOLD.OTF') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('MYRIADPRO-SEMIBOLDIT.OTF') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

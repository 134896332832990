@font-face {
  font-family: 'Caecilia LT Pro';
  src: url('CaeciliaLTStd-Bold.otf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Caecilia LT Pro';
  src: url('CaeciliaLTStd-BoldItalic.otf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Caecilia Heavy LT Pro';
  src: url('CaeciliaLTStd-Heavy.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Caecilia Heavy LT Pro';
  src: url('CaeciliaLTStd-HeavyItalic.otf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Caecilia LT Pro';
  src: url('CaeciliaLTStd-Italic.otf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Caecilia LT Pro Light';
  src: url('CaeciliaLTStd-Light.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Caecilia LT Pro Light';
  src: url('CaeciliaLTStd-LightItalic.otf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Caecilia LT Pro Roman';
  src: url('CaeciliaLTStd-Roman.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
